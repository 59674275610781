import { useEffect, useState } from 'react';

import { useCourseV2 } from '@/administration/pages/Course/store/course/useCourseV2';
import { useSaveCourse } from '@/administration/pages/Course/store/courseSave/useSaveMyCourse';
import { useXAPISession, XAPISession } from '@/store/xapi/useXAPISession';
import { useXAPIClient } from '@/store/xapi/useXAPIClient';
import { useModularLearningViewerV2 } from '@/pages/ModularLearning/useModularLearningViewerV2';
import { Activity, TreeActivity } from '@/administration/pages/Course/store/activity/types';
import { Course, CourseV2 } from '@/administration/pages/Course/store/course/types';
import { Progress } from '@/administration/pages/Course/store/progress/types';
import { ActivityId } from '@/administration/pages/Course/store/types';
import { getActiveDefaultActivityIndex } from '../activity/getActiveDefaultActivityIndex';
import { deepMerge } from '@/utils/datastructure/deep-merge';
import { usePluginTrackActivityProgress } from './plugins/useTrackActivityProgress';
import { usePluginTrackCourseProgress } from './plugins/useTrackCourseProgress';
import { usePluginTrackLastActiveActivity } from './plugins/useTrackLastActiveActivity';
import { usePluginAddReactionActivity } from './plugins/useAddReactionActivity';
import { usePluginAddResultsActivity } from './plugins/useAddResultsActivity';

const AUTO_NAVIGATION_TIMEOUT_DELAY = 500;

type UseCourseViewerV2Props = {
  id: number;
};

export type UseCourseViewerV2Result = {
  course?: CourseV2;
  activities: Activity[];
  treeActivities: TreeActivity[];
  progress?: Progress;
  xapiSession?: XAPISession;
  startCourse: () => void;
  onSetActivityActive: (activityId: ActivityId) => void;
  onSetPrevActivityActive: () => void;
  onSetNextActivityActive: () => void;
  onSetActivityActiveByIndex: (index: number) => void;
  onSaveCourse: () => void;
  activityActiveCount: number;
  previousActiveActivityIndex: number;
  activitiesTotalCount: number;
  isCourseSaved: boolean;
  loadingLearning: boolean;
  loadingProgressInitialized: boolean;
  loadingProgress: boolean;
  loadingSavedLearning: boolean;
  loadingStartCourse: boolean;
  isDrawerOpen: boolean;
  setDrawerOpen: (value: boolean) => void;
  onActivityChange: (id: ActivityId, diff: Partial<Activity>) => void;
};

export const useCourseViewerV2 = ({ id }: UseCourseViewerV2Props): UseCourseViewerV2Result => {
  const { data, loading: loadingLearning } = useCourseV2(id);
  const {
    handleSavedLearnings: handleSaveCourse,
    isSaved: isCourseSaved,
    loading: loadingSavedLearning,
  } = useSaveCourse(data?.course?.id);
  const { session, loadingSession, register, refetchSession } = useXAPISession(Number(data?.course?.id));
  const xapiClient = useXAPIClient(session);

  const pluginTrackActivityProgress = usePluginTrackActivityProgress({ xapiSession: session, xapiClient });
  const pluginTrackCourseProgress = usePluginTrackCourseProgress({
    xapiSession: session,
    xapiClient,
    course: { id: data?.course?.id, title: data?.course?.title },
  });
  const pluginTrackLastActiveActivity = usePluginTrackLastActiveActivity({ xapiSession: session, xapiClient });
  const pluginAddReactionActivity = usePluginAddReactionActivity({
    xapiSession: session,
    xapiClient,
    course: { id: data?.course?.id, title: data?.course?.title },
  });
  const pluginAddResultsActivity = usePluginAddResultsActivity({
    xapiSession: session,
    xapiClient,
    course: data?.course as unknown as Course,
  });

  const [loadingStartCourse, setLoadingStartCourse] = useState(false);
  const [loadingProgressInitialized, setLoadingProgressInitialized] = useState(false);

  const arePluginsInitialized =
    pluginTrackActivityProgress.isInitialized &&
    pluginTrackCourseProgress.isInitialized &&
    pluginAddReactionActivity.isInitialized &&
    pluginAddResultsActivity.isInitialized &&
    pluginTrackLastActiveActivity.isInitialized;

  const {
    activities,
    setDrawerOpen,
    isDrawerOpen,
    setActivities,
    treeActivities,
    activityActiveCount,
    onSetActivityActive,
    activitiesTotalCount,
    onSetPrevActivityActive,
    onSetNextActivityActive,
    previousActiveActivityIndex,
    onSetActivityActiveByIndex,
  } = useModularLearningViewerV2();

  // Update progress initialized flag
  useEffect(() => {
    if (loadingProgressInitialized || loadingLearning || loadingSession) return;

    setLoadingProgressInitialized(true);
  }, [loadingLearning, loadingSession, loadingProgressInitialized]);

  useEffect(() => {
    if (!data?.activities || !arePluginsInitialized) return;

    let nextActivities = data?.activities || [];
    nextActivities = pluginTrackActivityProgress.onLoadTransformActivities(nextActivities);
    nextActivities = pluginAddReactionActivity.onLoadTransformActivities(nextActivities);
    nextActivities = pluginAddResultsActivity.onLoadTransformActivities(nextActivities);
    nextActivities = pluginTrackLastActiveActivity.onLoadTransformActivities(nextActivities);

    const activityToActivateIndex = getActiveDefaultActivityIndex(nextActivities || []);
    if (activityToActivateIndex !== -1) {
      // we found a target activity to activate :)
      nextActivities[activityToActivateIndex] = { ...nextActivities[activityToActivateIndex], active: true };
    }

    setActivities(nextActivities || []);
  }, [loadingLearning, arePluginsInitialized]);

  useEffect(() => {
    if (!arePluginsInitialized) return;

    pluginTrackActivityProgress.onActivitesUpdate(activities);
    pluginTrackCourseProgress.onActivitesUpdate(activities);
    pluginTrackLastActiveActivity.onActivitesUpdate(activities);

    const resultsActivity = pluginAddResultsActivity.onActivitesUpdateCreateActivity(activities);
    if (resultsActivity) {
      setActivities((previous) => [...previous, resultsActivity]);

      setTimeout(() => onSetActivityActive({ activityId: resultsActivity.id }), AUTO_NAVIGATION_TIMEOUT_DELAY);
    }
  }, [activities]);

  const onActivityChange = (id: ActivityId, diff: Partial<Activity>): void => {
    setActivities((oldActivities: Activity[]) =>
      oldActivities.map((activityItem: Activity) => (activityItem.id !== id ? activityItem : deepMerge(activityItem, diff)))
    );
  };

  const handleStartCourse = async () => {
    try {
      if (session) return;

      setLoadingStartCourse(true);

      await refetchSession();

      if (session) return;

      await register();

      await refetchSession();
    } catch (e) {
    } finally {
      setLoadingStartCourse(false);
    }
  };

  const progress = {
    ...(data?.course?.userLastParticipation ? data?.course?.userLastParticipation : {}),
    isStarted: !!session?.registration,
  } as Progress;

  return {
    course: data?.course,
    activities,
    treeActivities,
    progress,
    xapiSession: session,

    startCourse: handleStartCourse,
    onSetActivityActive: (activityId) => onSetActivityActive({ activityId }),
    onSetPrevActivityActive,
    onSetNextActivityActive,
    onSetActivityActiveByIndex,
    onSaveCourse: handleSaveCourse,

    activityActiveCount,
    previousActiveActivityIndex,
    activitiesTotalCount,

    isCourseSaved,

    loadingLearning: loadingLearning,
    loadingProgressInitialized: loadingProgressInitialized,
    loadingProgress: loadingSession || loadingLearning,
    loadingSavedLearning,
    loadingStartCourse,
    isDrawerOpen,
    setDrawerOpen,
    onActivityChange,
  };
};
