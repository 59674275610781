import { useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';

import { QuizViewContext } from '@/component/customEditorV2/ui/elements/QuizQuestionElement/View/QuizViewContext';
import { Button } from '@/ui/Button';
import { useSubmitLearningActivityQuizAnswersMutationRemote } from '@/store/v2';

const RootStyled = styled.div`
  /**
    TODO (for later PRs)
    Remove it after setting up the correct course view layout.
   */
  // Start of removal target block
  @media (min-width: ${(p) => p.theme.breakpoints.desktopLarge.end}px) {
    max-width: 920px;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  padding-left: 58px;
  padding-right: 58px;
  max-width: 100%;
  margin-bottom: 16px;
  transform: translateY(-40px);
  // End of removal target block
`;

const WarningStyled = styled.div`
  ${({ theme }) => theme.typography.caption};
  color: ${({ theme }) => theme.colors.error.main};
  margin-bottom: 8px;
`;

export const SubmitSection = ({ onSubmit }: { onSubmit: () => void }): JSX.Element => {
  const { answeredQuestions, learningActivityId } = useContext(QuizViewContext);
  const [submitWarningShown, setSubmitWarningShown] = useState(false);
  const [wasSubmitTriggered, setWasSubmitTriggered] = useState(false);

  const isSumbissionValid = useMemo(() => {
    const allQuestionsCount = answeredQuestions.length;
    const allAnsweredQuestionsCount = answeredQuestions.filter((question) => question.answersIds.size).length;

    return allQuestionsCount === allAnsweredQuestionsCount;
  }, [answeredQuestions]);

  useEffect(() => {
    if (wasSubmitTriggered) {
      setSubmitWarningShown(!isSumbissionValid);
    }
  }, [isSumbissionValid, wasSubmitTriggered]);

  const [submitLearningActivityQuizAnswers, { loading }] = useSubmitLearningActivityQuizAnswersMutationRemote();

  const handleSubmit = async () => {
    if (!isSumbissionValid) {
      setSubmitWarningShown(true);
      setWasSubmitTriggered(true);
      return;
    }

    await submitLearningActivityQuizAnswers({
      variables: {
        input: {
          learningActivityId: learningActivityId,
          version: uuid(),
          answers: answeredQuestions.map((question) => ({
            questionId: question.questionId,
            selectedOptionIds: Array.from(question.answersIds),
          })),
        },
      },
    });
    onSubmit();
  };
  return (
    <RootStyled>
      {submitWarningShown && <WarningStyled>All questions must be answered.</WarningStyled>}
      <Button variant="primary" onClick={handleSubmit} $loading={loading}>
        Submit
      </Button>
    </RootStyled>
  );
};
