import styled from 'styled-components';

import { Button } from '@/ui/Button';

const RootStyled = styled.div`
  /**
    TODO (for later PRs)
    Remove it after setting up the correct course view layout.
   */
  // Start of removal target block
  @media (min-width: ${(p) => p.theme.breakpoints.desktopLarge.end}px) {
    max-width: 920px;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  padding-left: 58px;
  padding-right: 58px;
  max-width: 100%;
  margin-bottom: 16px;
  transform: translateY(-40px);
  // End of removal target block
`;

export const RetakeSection = ({ onRetakeClick }: { onRetakeClick: () => void }): JSX.Element => {
  return (
    <RootStyled>
      <Button icon={['far', 'arrows-repeat']} variant="secondary" onClick={onRetakeClick}>
        Retake
      </Button>
    </RootStyled>
  );
};
