import { useTheme } from 'styled-components';

import { IconPropType } from '@/ui/Icon';
import { BadgeVariant } from '../../types';

export const useLabelState = (
  type: BadgeVariant
): {
  icon?: IconPropType;
  backgroundColor: string;
  skeletonWidth: number;
  skeletonHeight: number;
} => {
  const theme = useTheme();

  const state: Record<
    BadgeVariant,
    {
      icon?: IconPropType;
      backgroundColor: string;
      skeletonWidth: number;
      skeletonHeight: number;
    }
  > = {
    'badge-required': {
      icon: ['far', 'star-exclamation'],
      backgroundColor: theme.colors.error.container,
      skeletonHeight: 16,
      skeletonWidth: 79,
    },
    'badge-recommended': {
      icon: ['far', 'star'],
      backgroundColor: theme.colors.tertiary.container,
      skeletonHeight: 16,
      skeletonWidth: 112,
    },
    'badge-active': {
      icon: ['far', 'bolt'],
      backgroundColor: theme.colors.primary.container,
      skeletonHeight: 16,
      skeletonWidth: 60,
    },
    'badge-dot': {
      backgroundColor: theme.colors.primary.outline,
      skeletonHeight: 6,
      skeletonWidth: 6,
    },
    'badge-text': {
      backgroundColor: theme.colors.neutral.outline1,
      skeletonHeight: 16,
      skeletonWidth: 60,
    },
    'badge-success': {
      backgroundColor: theme.colors.success.container,
      skeletonHeight: 16,
      skeletonWidth: 60,
    },
    'badge-error': {
      backgroundColor: theme.colors.error.container,
      skeletonHeight: 16,
      skeletonWidth: 60,
    },
    'badge-survey': {
      icon: ['far', 'square-poll-vertical'],
      backgroundColor: theme.colors.error.container,
      skeletonHeight: 16,
      skeletonWidth: 79,
    }
  };

  return state[type];
};
