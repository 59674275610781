import { XAPISession } from '@/store/xapi/useXAPISession';
import { XAPIClient } from '@/store/xapi/useXAPIClient';
import { Activity } from '@/administration/pages/Course/store/activity/types';
import { Course } from '@/administration/pages/Course/store/course/types';
import { createResultActivity } from '@/administration/pages/Course/store/activity/transform';
import { getAreAllActivitiesCompleted } from '../../activity/getAreAllActivitiesCompleted';
import { getNextOrderIndex } from '../../activity/getNextOrderIndex';
import { RLearningActivityType } from '@/store/v2';
import { ActivityType } from '@/administration/pages/Course/store/activity/types';

type UsePluginAddResultsActivityProps = {
  xapiSession?: XAPISession;
  xapiClient: XAPIClient;
  course?: Course;
};

export type UsePluginAddResultsActivity = {
  isInitialized: boolean;
  loading: boolean;

  onLoadTransformActivities: (activities: Activity[]) => Activity[];
  onActivitesUpdateCreateActivity: (activities: Activity[]) => Activity | void;
};

export const usePluginAddResultsActivity = ({ course }: UsePluginAddResultsActivityProps): UsePluginAddResultsActivity => {
  const onLoadTransformActivities = (activities: Activity[]): Activity[] => {
    const areAllActivitiesCompleted = getAreAllActivitiesCompleted(activities);
    if (!areAllActivitiesCompleted) return activities;

    return [...activities, createResultActivity({ course, order: getNextOrderIndex(activities) })];
  };

  const onActivitesUpdateCreateActivity = (activities: Activity[]): Activity | void => {
    const areAllActivitiesCompleted = getAreAllActivitiesCompleted(activities);
    if (!areAllActivitiesCompleted) return;

    if (activities.some((a) => a.type === (ActivityType.Result as unknown as RLearningActivityType))) return;

    return createResultActivity({ course, order: getNextOrderIndex(activities) });
  };

  return {
    isInitialized: true,
    loading: false,
    onLoadTransformActivities,
    onActivitesUpdateCreateActivity,
  };
};
