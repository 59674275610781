import React from 'react';

import './collectionHero.scss';
import { convertTitle } from '@/utils/misc';
import { LearningCollection } from '@/types/learning/learning-catalog';
import { getCollectionProviderMeta } from '@/store/providers';
import { RightButtons } from '../Learning/components/Hero/RightButtons';
import { ProviderIcon } from '@/component/ProviderIcon/ProviderIcon';

interface Props {
  learningCollection: Readonly<LearningCollection>;
}

export const CollectionHero = ({ learningCollection }: Props): JSX.Element => {
  const providerMeta = getCollectionProviderMeta(learningCollection);

  return (
    <div className="hero is-cls is-padded">
      <div className="hero-body">
        <div className="container">
          <div className="grid">
            <div className="gc gc-8-d gc-8-t gc-12-lp">
              <h1 className="header1 has-bottom-margin" styleName="course-title">
                {learningCollection.title}
              </h1>
              {learningCollection.teaser && <div className="content preamble">{learningCollection.teaser}</div>}
              <RightButtons
                id={learningCollection.id}
                entity="collection"
                editLink={
                  learningCollection.canEdit
                    ? `/administration/learning-collection/${learningCollection.id}/${convertTitle(learningCollection.title)}`
                    : undefined
                }
              />
            </div>
            <div className="gc gc-4-d gc-4-t gc-12-lp">
              <div
                styleName="image"
                style={{
                  ...(learningCollection.imageUrl ? { backgroundImage: `url(${learningCollection.imageUrl})` } : {}),
                }}
              >
                {providerMeta && (
                  <div styleName="logo-wrapper">
                    <ProviderIcon provider={providerMeta.provider} size="3x" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
