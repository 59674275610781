import styled from 'styled-components';
import { useEffect, useState } from 'react';

import { CustomEditorV2Readonly } from '@/component/customEditorV2';
import { Question, QuizViewContext } from '@/component/customEditorV2/ui/elements/QuizQuestionElement/View/QuizViewContext';
import { SubmitSection } from '@/administration/pages/Course/pages/quiz/View/ViewQuizPage/SubmitSection';
import { useMyLearningActivityQuizSubmissionsQueryRemote } from '@/store/v2';
import { ResultsSection } from '@/administration/pages/Course/pages/quiz/View/ViewQuizPage/Results';
import { RetakeSection } from '@/administration/pages/Course/pages/quiz/View/ViewQuizPage/RetakeSection';
import { RetakeModal } from '@/administration/pages/Course/pages/quiz/View/ViewQuizPage/RetakeModal';

export const RootStyled = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.primary.onMain};
  color: ${({ theme }) => theme.colors.neutral.onContainer};
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0px 28px;
  border-radius: 20px;
  height: 100%;
  min-height: calc(100vh - 285px);
  justify-content: flex-start;
  align-items: flex-start;

  @media (min-width: ${(p) => p.theme.breakpoints.desktopLarge.end}px) {
    align-items: center;
  }
`;

export const ContentStyled = styled.div`
  width: 100%;
  max-width: 920px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  word-wrap: break-word;
  overflow-x: auto;
  gap: 24px;
`;

type ViewQuizPageProps = {
  id: string;
  text: string;
  onCompleted?: () => void;
  spaceId: number;
  remoteId: number;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ViewQuizPage = ({ id, text, spaceId, onCompleted, remoteId }: ViewQuizPageProps): JSX.Element | null => {
  const [retakeModalOpen, setRetakeModalOpen] = useState(false);
  const { data, loading, refetch } = useMyLearningActivityQuizSubmissionsQueryRemote({
    variables: {
      input: {
        includeOlderVersions: true,
        learningActivityQuizIdsIN: [remoteId],
      },
    },
  });
  const [answeredQuestions, setAnsweredQuestions] = useState<Question[]>([]);
  const [score, setScore] = useState(0);
  const [correctAnswersCount, setCorrectAnswersCount] = useState(0);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    submitted && onCompleted && onCompleted();
  }, [submitted]);

  const handleRetakeConfirmed = () => {
    setRetakeModalOpen(false);
    setSubmitted(false);
    setAnsweredQuestions([]);
  };

  const handleSubmitted = () => {
    refetch();
  };

  useEffect(() => {
    if (data?.myLearningActivityQuizSubmissions.length) {
      setAnsweredQuestions(() => {
        const answers = (data.myLearningActivityQuizSubmissions[0].answers as any[]).map((answer) => ({
          questionId: answer.questionId as string,
          answersIds: new Set(answer.selectedOptions.map((option: any) => (option as any).id)) as Set<string>,
        }));

        return answers;
      });

      const fetchedQuestionsCount = data.myLearningActivityQuizSubmissions[0].answers.length;
      const fetchedScore = data.myLearningActivityQuizSubmissions[0].score;

      /**
       * To omit going through the whole quiz and matching the correct answers count,
       * we taking it directly from the score.
       */
      const correctAnswersCount = Math.round((fetchedQuestionsCount * fetchedScore) / 100);

      setScore(fetchedScore);
      setCorrectAnswersCount(correctAnswersCount);
      setSubmitted(true);
    }
  }, [loading, data?.myLearningActivityQuizSubmissions]);

  if (loading) return null;

  return (
    <RootStyled key={id}>
      <ContentStyled>
        <QuizViewContext.Provider
          value={{
            answeredQuestions: answeredQuestions,
            setAnsweredQuestions,
            learningActivityId: remoteId,
            submitted,
          }}
        >
          <RetakeModal onClose={() => setRetakeModalOpen(false)} onRetake={handleRetakeConfirmed} open={retakeModalOpen} />
          {submitted && <ResultsSection correctAnswersCount={correctAnswersCount} score={score} />}
          <CustomEditorV2Readonly
            value={text}
            style={{ fontSize: 14 }}
            toggledFeatures={{ trimEndEmptyParagraphs: true }}
            $fullSize
            minimized
            spaceId={spaceId}
          />
          {submitted ? (
            <RetakeSection onRetakeClick={() => setRetakeModalOpen(true)} />
          ) : (
            <SubmitSection onSubmit={handleSubmitted} />
          )}
        </QuizViewContext.Provider>
      </ContentStyled>
    </RootStyled>
  );
};
