/* eslint-disable import/no-unused-modules */
import { v4 as uuid } from 'uuid';

import { RLearningActivityType, RLearningStatus, RLearningV2ByIdQuery } from '@/store/v2';
import { Course } from '../course/types';
import { minutesToHoursMinutes } from '@/utils/time';
import { Activity, ActivityType, ActivityContent } from './types';
import { ActivityId, CourseId } from '../types';
import { LearningVisibility } from '@/types/learning';
import { RESULTS_PAGE_ID } from '@/administration/pages/Course/store/page/transform';

const DEFAULT_RICH_TEXT_PAGE_VALUE = '[{"children":[{"text":""}]}]';
const DEFAULT_RICH_TEXT_QUIZ_VALUE =
  `[{"children":[{"text":""}],"type":"quizQuestion","questionType":{"label":"Select one option","value":"SINGLE_SELECT","icon":["far","circle-dot"]},"title":"","answers":[{"label":"","isCorrect":false,"id":"${uuid()}"}"},{"label":"","isCorrect":false,"id":"${uuid()}"},{"label":"","isCorrect":true,"id":"${uuid()}"}]},{"type":"paragraph","children":[{"text":""}]}]`;

const DEFAULT_RICH_TEXT_VALUE_BY_TYPE: Record<string, string> = {
  [RLearningActivityType.Page]: DEFAULT_RICH_TEXT_PAGE_VALUE,
  [RLearningActivityType.Quiz]: DEFAULT_RICH_TEXT_QUIZ_VALUE,
};

export type InitialActivityProps = {
  type: RLearningActivityType;
  learningId: number;
  order?: number;
  parentActivityId?: number;
};

export const createActivity = ({
  type,
  order = 0,
  parentActivityId,
  initialTitle,
}: {
  order?: number;
  parentActivityId?: string;
  type: RLearningActivityType;
  initialTitle: string;
}): Activity => {
  return {
    id: `activity-r-${uuid()}` as never, // todo type it
    type,
    content: {
      title: initialTitle,
      text: DEFAULT_RICH_TEXT_VALUE_BY_TYPE[type] || undefined,
    } as ActivityContent,
    order,
    parentActivityId,
  };
};

export const createReactionActivity = ({
  order,
  completed,
}: {
  course?: Course;
  order: number;
  completed?: boolean;
}): Activity => {
  return {
    order,
    id: `activity-r-reaction` as ActivityId,
    type: ActivityType.Reaction as unknown as RLearningActivityType,
    content: {
      title: 'What will you do next?',
    } as ActivityContent,
    active: false,
    hasError: false,
    completed: !!completed,
  };
};

export const createResultActivity = ({ course, order }: { course?: Course; order: number }): Activity => {
  return {
    id: RESULTS_PAGE_ID as ActivityId,
    type: ActivityType.Result as unknown as RLearningActivityType,
    content: {
      title: 'Results',
      level: course?.level,
      effortHours: course?.effortHours,
      effortMinutes: course?.effortMinutes,
    } as ActivityContent,
    active: false,
    hasError: false,
    order,
  } as Activity;
};

const transformActivitiesFromRemote = (activities?: RLearningV2ByIdQuery['learningV2']['activities']): Activity[] => {
  const parsedActivities: Activity[] =
    activities?.map(
      (remoteActivity) =>
        ({
          id: `activity-r-${remoteActivity?.id || 0}`,
          remoteId: remoteActivity.id,
          parentActivityId: remoteActivity.parentActivityId && `activity-r-${remoteActivity?.parentActivityId || 0}`,
          remoteParentActivityId: remoteActivity.parentActivityId,
          order: remoteActivity.order,
          type: remoteActivity.type,
          content: remoteActivity.data,
        } as unknown as Activity)
    ) || [];

  return parsedActivities;
};

export const transformFromRemoteLearningV2 = (
  rLearning: RLearningV2ByIdQuery['learningV2'] | undefined
): [Course | undefined, Activity[] | undefined] => {
  if (!rLearning) return [undefined, undefined];

  const { hours, minutes } = minutesToHoursMinutes(rLearning?.effort || 0);

  return [
    {
      id: rLearning.id as unknown as CourseId,
      spaceId: rLearning.spaceId || 0,
      title: rLearning.title || '',
      effortHours: hours,
      effortMinutes: minutes,
      image: rLearning.image || '',
      language: rLearning.languageId || 0,
      meta: rLearning.meta,
      level: rLearning?.meta?.level || 'Basic',
      partner: '', // tood,
      teaser: rLearning.teaser || '',
      visibility: rLearning.visibility as unknown as LearningVisibility,
      status: rLearning.status as unknown as RLearningStatus,
      userLastParticipation: rLearning?.userLastParticipation,
      updatedAt: rLearning.updatedAt || '',
      updateByUserFirstName: rLearning.updateByUser?.firstname || '',
      updateByUserLastName: rLearning.updateByUser?.lastname || '',
    } as Course,
    transformActivitiesFromRemote(rLearning.activities),
  ];
};
