import styled from 'styled-components';

import { LinearProgressBar } from '@/ui/ProgressBar';
import { ProgressBarStyled } from '@/ui/ProgressBar/variants/LinearProgressBar/styles';

const DRAWER_WIDTH = 312;
export const NAVBAR_HEIGHT = 84;
export const PROGRESS_HEIGHT = 4;

export const RootStyled = styled.div`
  background: ${({ theme }) => theme.tones.neutral[99]};
  height: 100vh;
  overflow: scroll;
`;

export const NavStyled = styled.div`
  padding: 24px;
  background: ${({ theme }) => theme.tones.neutral[99]};
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 30;
`;

export const ContentRootStyled = styled.div<{ drawerOpen: boolean }>`
  display: flex;
  flex-direction: column;

  height: 100%;

  padding: ${({ drawerOpen }) => (drawerOpen ? `${NAVBAR_HEIGHT}px 0 0 ${DRAWER_WIDTH + 24}px` : `${NAVBAR_HEIGHT}px 0 0 0`)};
  transition: padding-left 400ms ease-in-out;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
`;

export const DrawerStyled = styled.div<{ top: number; isOpen: boolean }>`
  background: ${({ theme }) => theme.tones.neutral[99]};
  position: fixed;
  top: ${({ top }) => top + 8}px;
  bottom: 0;
  z-index: 100;
  left: ${({ isOpen }) => (isOpen ? '24px' : `-${DRAWER_WIDTH + 24}px`)};
  width: ${DRAWER_WIDTH}px;
  transition: left 400ms ease-in-out;
`;

export const Progress = styled(LinearProgressBar)`
  position: fixed;
  top: ${NAVBAR_HEIGHT}px;
  left: 0;
  right: 0;
  & ${ProgressBarStyled} {
    height: ${PROGRESS_HEIGHT}px;
  }
  z-index: 1;
`;
