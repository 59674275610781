import { XAPISession } from '@/store/xapi/useXAPISession';
import { StateId, useXAPIState } from '@/store/xapi/useXAPIState';
import { XAPIClient } from '@/store/xapi/useXAPIClient';
import { Activity } from '@/administration/pages/Course/store/activity/types';

type UseTrackActivityProgressProps = {
  xapiSession?: XAPISession;
  xapiClient: XAPIClient;
};

export type UseTrackActivityProgress = {
  isInitialized: boolean;
  loading: boolean;

  onLoadTransformActivities: (activities: Activity[]) => Activity[];
  onActivitesUpdate: (activities: Activity[]) => void;
};

const activityKey = (activity: Activity): string => String(activity?.remoteId);

export const usePluginTrackActivityProgress = ({
  xapiSession,
  xapiClient,
}: UseTrackActivityProgressProps): UseTrackActivityProgress => {
  const xapiCompletedActivities = useXAPIState<Record<string, boolean>>(StateId.COMPLETED_ACTIVITIES, xapiSession, {});
  const xapiOpenedActivities = useXAPIState<Record<string, boolean>>(StateId.OPENED_ACTIVITIES, xapiSession, {});

  const trackActivitesCompletions = (activities: Activity[]) => {
    const unsavedActivities = activities.filter(
      (activity) => activity.remoteId && activity.completed !== Boolean(xapiCompletedActivities?.data?.[activityKey(activity)])
    );
    if (!unsavedActivities.length) return;

    const diff = unsavedActivities.reduce(
      (acc, activity) => ({ ...acc, [activityKey(activity)]: Boolean(activity.completed) }),
      {}
    );

    xapiCompletedActivities.setState({ ...(xapiCompletedActivities?.data || {}), ...diff });
    unsavedActivities
      .filter((activity) => activity.completed && activity.remoteId)
      .forEach((activity) => xapiClient.setLearningActivityCompleted(Number(activity.remoteId), activity?.content?.title || ''));
  };

  const trackActivitesOpened = (activities: Activity[]) => {
    const openedActivity = activities.find((a) => a.active);
    if (!openedActivity || !openedActivity.remoteId) return;

    if (Boolean(xapiOpenedActivities.data?.[activityKey(openedActivity)])) return;

    xapiOpenedActivities.setState({ ...(xapiCompletedActivities?.data || {}), [activityKey(openedActivity)]: true });
    xapiClient.setLearningActivityRegistred(Number(openedActivity.remoteId), openedActivity?.content?.title || '');
  };

  const onLoadTransformActivities = (activities: Activity[]): Activity[] => {
    if (!activities.length || !xapiCompletedActivities.isInitialized) return activities;

    return activities.map((activity) => ({
      ...activity,
      completed: activity.completed || Boolean(xapiCompletedActivities?.data?.[activityKey(activity)]),
    }));
  };

  const onActivitesUpdate = (activities: Activity[]) => {
    if (!activities.length || !xapiCompletedActivities.isInitialized || !xapiOpenedActivities.isInitialized) return activities;

    trackActivitesCompletions(activities);
    trackActivitesOpened(activities);
  };

  return {
    isInitialized: xapiCompletedActivities.isInitialized && xapiOpenedActivities.isInitialized,
    loading: xapiCompletedActivities.loading && xapiOpenedActivities.loading,
    onLoadTransformActivities,
    onActivitesUpdate,
  };
};
