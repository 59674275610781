import { XAPISession } from '@/store/xapi/useXAPISession';
import { StateId, useXAPIState } from '@/store/xapi/useXAPIState';
import { XAPIClient } from '@/store/xapi/useXAPIClient';
import { Activity } from '@/administration/pages/Course/store/activity/types';

type UsePluginTrackLastActiveActivityProps = {
  xapiSession?: XAPISession;
  xapiClient: XAPIClient;
};

export type UsePluginTrackLastActiveActivity = {
  isInitialized: boolean;
  loading: boolean;

  onLoadTransformActivities: (activities: Activity[]) => Activity[];
  onActivitesUpdate: (activities: Activity[]) => void;
};

export const usePluginTrackLastActiveActivity = ({
  xapiSession,
}: UsePluginTrackLastActiveActivityProps): UsePluginTrackLastActiveActivity => {
  const xapiLastActivityIdSeen = useXAPIState(StateId.LAST_ACTIVITY_SEEN, xapiSession, 0);

  const onLoadTransformActivities = (activities: Activity[]): Activity[] => {
    if (!activities.length || !xapiLastActivityIdSeen.isInitialized) return activities;

    const lastSeenActivity = activities?.find((activity) => activity?.remoteId === xapiLastActivityIdSeen.data);
    if (!lastSeenActivity) return activities;

    return activities.map((activity) =>
      activity.id === lastSeenActivity.id ? { ...activity, active: true } : { ...activity, active: false }
    );
  };

  const onActivitesUpdate = (activities: Activity[]) => {
    if (!activities.length || !xapiLastActivityIdSeen.isInitialized) return activities;

    const activeActivity = activities.find((activity) => activity.active);

    if (!activeActivity?.remoteId || activeActivity.remoteId === xapiLastActivityIdSeen?.data) return;

    xapiLastActivityIdSeen.setState(activeActivity.remoteId);
  };

  return {
    isInitialized: xapiLastActivityIdSeen.isInitialized,
    loading: xapiLastActivityIdSeen.loading,
    onLoadTransformActivities,
    onActivitesUpdate,
  };
};
