import { Activity } from './types';
import { RLearningActivityType } from '@/store/v2';
import { ActivityType } from '@/administration/pages/Course/store/activity/types';

export const getAreAllActivitiesCompleted = (activities: Activity[]): boolean => {
  return (
    activities
      // we should filter out for modules and result
      .filter(
        (activity) =>
          ![ActivityType.Result as unknown as RLearningActivityType, RLearningActivityType.Module].includes(activity.type)
      )
      .every((activity) => activity.completed)
  );
};
