import { RLearningActivityType } from '@/store/v2';
import { XAPISession } from '@/store/xapi/useXAPISession';
import { XAPIClient } from '@/store/xapi/useXAPIClient';
import { ActivityType } from '@/administration/pages/Course/store/activity/types';
import { StateId, useXAPIState } from '@/store/xapi/useXAPIState';
import { Activity } from '@/administration/pages/Course/store/activity/types';
import { getAreAllActivitiesCompleted } from '../../activity/getAreAllActivitiesCompleted';

type UseTrackCourseProgressProps = {
  xapiSession?: XAPISession;
  xapiClient: XAPIClient;
  course: {
    id?: string;
    title?: string;
  };
};

export type UseTrackCourseProgress = {
  isInitialized: boolean;
  loading: boolean;

  onActivitesUpdate: (activities: Activity[]) => void;
};

export const usePluginTrackCourseProgress = ({
  xapiSession,
  xapiClient,
  course,
}: UseTrackCourseProgressProps): UseTrackCourseProgress => {
  const xapiLastCourseProgress = useXAPIState<number>(StateId.LAST_COURSE_PROGRESS, xapiSession, 0);

  const trackCourseCompletion = (activities: Activity[]) => {
    const areAllActivitiesCompleted = getAreAllActivitiesCompleted(activities);
    if (!areAllActivitiesCompleted) return;

    xapiClient.setLearningCompleted(Number(course?.id), course?.title || '');
  };

  const trackCourseProgress = (activities: Activity[]) => {
    const activitesWithProgress = activities.filter(
      (activity) =>
        ![ActivityType.Result as unknown as RLearningActivityType, RLearningActivityType.Module].includes(activity.type)
    );
    const completedActivities = activities.filter((activity) => activity.completed);

    if (!activitesWithProgress.length) return;

    const progress = (completedActivities.length * 100) / activitesWithProgress.length;
    if (progress === 0 || (xapiLastCourseProgress.data || 0) >= progress) return;

    xapiLastCourseProgress.setState(progress);

    xapiClient.setLearningProgressed(Number(course?.id), course?.title || '', progress);
  };

  const onActivitesUpdate = (activities: Activity[]) => {
    if (!activities.length || !xapiLastCourseProgress.isInitialized) return activities;

    trackCourseCompletion(activities);
    trackCourseProgress(activities);
  };

  return {
    isInitialized: xapiLastCourseProgress.isInitialized,
    loading: xapiLastCourseProgress.loading,
    onActivitesUpdate,
  };
};
